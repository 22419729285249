import DocumentSearch from '../DocumentSearch/index.vue';
import PdfRenderer from './pdf-renderer';
import debounce from 'lodash/debounce';
import { createAndMountApp } from '../../utils/vue-utils';
import { vueI18n } from '../../i18n';
import { createTocController, generateHtmlTocItems, wrapTocItems } from '../../utils/function';
import EnrichmentsManager from './enrichments-manager';
import i18next from 'i18next';
var OffCanvas = /** @class */ (function () {
    function OffCanvas(element) {
        this.offCanvas = new window.bootstrap.Offcanvas(element);
        this.body = element.querySelector('[data-offcanvas-body]');
    }
    OffCanvas.prototype.show = function () {
        this.offCanvas.show();
    };
    OffCanvas.prototype.hide = function () {
        this.offCanvas.hide();
    };
    return OffCanvas;
}());
var DocumentContent = /** @class */ (function () {
    function DocumentContent(root) {
        var _this = this;
        this.enrichmentsManager = null;
        this.tocController = null;
        this.tocShowActiveItemOnly = false;
        this.tocItemIndex = new Map();
        this.activeTocItem = null;
        this.getTocItems = function () {
            var items = [];
            if (_this.displayType === 'akn') {
                var tocElement = _this.root.querySelector('#akn_toc_json');
                if (tocElement) {
                    items = JSON.parse(tocElement.textContent) || [];
                }
            }
            else if (_this.displayType === 'html') {
                var content = _this.root.querySelector('.content__html');
                if (content) {
                    items = generateHtmlTocItems(content);
                    wrapTocItems(content, items);
                }
            }
            if (_this.tocShowActiveItemOnly) {
                // Add a "Show full text" item to the top of the TOC when the "show active item only" option is enabled
                items.unshift({
                    tag: 'H1',
                    title: i18next.t('Show full text'),
                    id: '',
                    children: []
                });
            }
            // recursively add parent and depth information
            function addParentAndDepth(items, parent, depth) {
                if (parent === void 0) { parent = null; }
                if (depth === void 0) { depth = 1; }
                for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
                    var item = items_1[_i];
                    item.parent = parent;
                    item.depth = depth;
                    addParentAndDepth(item.children, item, depth + 1);
                }
            }
            addParentAndDepth(items);
            return items;
        };
        this.root = root;
        this.documentElement = this.root.querySelector('.content');
        this.displayType = this.root.getAttribute('data-display-type') || '';
        this.setupTabs();
        this.setupOffCanvasNav();
        this.setupPdf();
        this.setupSearch();
        this.setupEnrichments();
        this.setSharedPortion();
        if (this.displayType !== 'pdf') {
            // for PDFs, this will be done once the pages are rendered
            this.setupPopups();
        }
    }
    DocumentContent.prototype.setupTabs = function () {
        var tocTabTriggerEl = this.root.querySelector('#toc-tab');
        var searchTabTriggerEl = this.root.querySelector('#navigation-search-tab');
        var pdfPreviewsTabTriggerEl = this.root.querySelector('#pdf-previews-tab');
        var tocCreated = this.setupToc();
        // If toc setup and mounted successfully, activate toc tab otherwise activate search tab
        if (tocCreated && tocTabTriggerEl) {
            tocTabTriggerEl.classList.remove('d-none');
            var tocTab = new window.bootstrap.Tab(tocTabTriggerEl);
            tocTab.show();
        }
        else if (this.displayType === 'pdf' && pdfPreviewsTabTriggerEl) {
            var pdfPreviewsTab = new window.bootstrap.Tab(pdfPreviewsTabTriggerEl);
            pdfPreviewsTab.show();
        }
        else if (searchTabTriggerEl) {
            var searchTab = new window.bootstrap.Tab(searchTabTriggerEl);
            searchTab.show();
        }
    };
    DocumentContent.prototype.setupOffCanvasNav = function () {
        var navColumn = this.root.querySelector('#navigation-column');
        var navContent = this.root.querySelector('#navigation-content .navigation__inner');
        var navOffCanvasElement = this.root.querySelector('#navigation-offcanvas');
        if (navColumn && navOffCanvasElement && navContent) {
            this.navOffCanvas = new OffCanvas(navOffCanvasElement);
            if (this.navOffCanvas.body) {
                this.setupResponsiveContentTransporter(navColumn, this.navOffCanvas.body, navContent);
            }
        }
    };
    DocumentContent.prototype.setupPdf = function () {
        var _this = this;
        // if pdf setup pdf renderer instance
        if (this.displayType === 'pdf') {
            // get dataset attributes
            var pdfAttrsElement_1 = this.root.querySelector('[data-pdf]');
            if (pdfAttrsElement_1) {
                Object.keys(pdfAttrsElement_1.dataset).forEach(function (key) {
                    _this.root.dataset[key] = pdfAttrsElement_1.dataset[key];
                });
            }
            this.pdfRenderer = new PdfRenderer(this.root, this);
            this.pdfRenderer.onPreviewPanelClick = function () {
                var _a;
                (_a = _this.navOffCanvas) === null || _a === void 0 ? void 0 : _a.hide();
            };
            this.pdfRenderer.onPdfLoaded = function () {
                var _a;
                if (_this.enrichmentsManager) {
                    _this.enrichmentsManager.addPdfEnrichments();
                }
                _this.setupPopups();
                var urlParams = new URLSearchParams(window.location.search);
                var targetPage = urlParams.get('page');
                if (!targetPage)
                    return;
                (_a = _this.pdfRenderer) === null || _a === void 0 ? void 0 : _a.triggerScrollToPage(targetPage);
            };
        }
    };
    DocumentContent.prototype.setupSearch = function () {
        var _this = this;
        var targetMountElement = this.root.querySelector('[data-doc-search]');
        if (targetMountElement) {
            createAndMountApp({
                component: DocumentSearch,
                props: {
                    document: this.documentElement,
                    docType: this.displayType,
                    mountElement: targetMountElement
                },
                use: [vueI18n],
                mountTarget: targetMountElement
            });
            targetMountElement.addEventListener('going-to-snippet', function () {
                var _a;
                (_a = _this.navOffCanvas) === null || _a === void 0 ? void 0 : _a.hide();
            });
        }
    };
    DocumentContent.prototype.setupResponsiveContentTransporter = function (desktopElement, mobileElement, content) {
        var _this = this;
        var placeContent = function (vw) {
            var _a;
            // reference _variables.scss for grid-breakpoints
            if (vw < 992) {
                // transport content to mobile element on tablet/mobile view
                mobileElement.appendChild(content);
            }
            else {
                (_a = _this.navOffCanvas) === null || _a === void 0 ? void 0 : _a.hide();
                // transport content to desktop element on desktop view
                desktopElement.appendChild(content);
            }
        };
        var initialVw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        placeContent(initialVw);
        window.addEventListener('resize', debounce(function () {
            var vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            var inputs = Array.from(_this.root.querySelectorAll('input'));
            // If window resize was triggered by device virtual keyboard, dont place content
            if (inputs.some(function (input) { return input === document.activeElement; })) {
                return;
            }
            placeContent(vw);
        }, 200));
    };
    DocumentContent.prototype.setupToc = function () {
        var _this = this;
        this.tocShowActiveItemOnly = this.root.hasAttribute('data-toc-show-active-item-only');
        // If there is no toc item don't create and mount la-toc-controller
        var tocItems = this.getTocItems();
        if (!tocItems.length)
            return false;
        // index from id to TOC items
        var indexItems = function (items) {
            for (var _i = 0, items_2 = items; _i < items_2.length; _i++) {
                var item = items_2[_i];
                // @ts-ignore
                _this.tocItemIndex.set(item.id, item);
                indexItems(item.children);
            }
        };
        indexItems(tocItems);
        this.tocController = createTocController(tocItems);
        // @ts-ignore
        this.tocController.titleFilterPlaceholder = i18next.t('Search table of contents');
        var tocContainer = this.root.querySelector('.toc');
        if (!tocContainer)
            return;
        tocContainer.appendChild(this.tocController);
        if (this.tocShowActiveItemOnly && this.documentElement) {
            this.originalDocument = this.documentElement.cloneNode(true);
        }
        this.tocController.addEventListener('itemTitleClicked', function (e) {
            var _a, _b, _c;
            // @ts-ignore
            _this.setSharedPortion((_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.item) === null || _b === void 0 ? void 0 : _b.title);
            // hide off-canvas nav, if necessary
            (_c = _this.navOffCanvas) === null || _c === void 0 ? void 0 : _c.hide();
            if (_this.tocShowActiveItemOnly) {
                _this.showDocumentPortion(e.detail.target.getAttribute('href'));
            }
        });
        // now that the page has loaded, check if there is a hash in the URL and if so, activate the corresponding TOC item
        if (window.location.hash) {
            this.showDocumentPortion(window.location.hash);
        }
        return true;
    };
    DocumentContent.prototype.setupEnrichments = function () {
        var contentAndEnrichmentsElement = this.root.querySelector('.content-and-enrichments');
        if (!contentAndEnrichmentsElement)
            return;
        this.enrichmentsManager = new EnrichmentsManager(contentAndEnrichmentsElement);
    };
    DocumentContent.prototype.setupPopups = function () {
        if (!this.documentElement)
            return;
        // if the document isn't using la-akoma-ntoso, then fiddle existing <a> elements to ensure that the external popups
        // functionality finds them (it expects AKN-style a tags)
        if (this.documentElement.tagName !== 'LA-AKOMA-NTOSO') {
            for (var _i = 0, _a = Array.from(this.documentElement.querySelectorAll('a[href^="/akn/"]')); _i < _a.length; _i++) {
                var a = _a[_i];
                a.classList.add('akn-ref');
                // @ts-ignore
                a.setAttribute('data-href', a.getAttribute('href'));
            }
        }
        var el = document.createElement('la-decorate-external-refs');
        el.akomaNtoso = this.documentElement;
        el.popups = true;
        el.provider = '//' + window.location.host;
        this.documentElement.appendChild(el);
    };
    /** Show only the portion of the document identified by the given ID, or the entire document if no ID is given. */
    DocumentContent.prototype.showDocumentPortion = function (id) {
        var _a;
        var _b;
        // strip leading #
        if (id && id.startsWith('#'))
            id = id.substring(1);
        if (this.originalDocument && this.documentElement) {
            if (!id) {
                // show entire document
                (_a = this.documentElement).replaceChildren.apply(_a, Array.from(this.originalDocument.children).map(function (node) { return node.cloneNode(true); }));
                this.activeTocItem = null;
            }
            else {
                // find the owning portion for this portion, by looking for the container that has a TOC entry of depth 1
                // @ts-ignore
                var tocItem = this.tocItemIndex.get(id);
                while (tocItem && tocItem.depth > 1) {
                    tocItem = tocItem.parent;
                }
                if (!tocItem)
                    return;
                // swap in the main owner of this portion, if necessary
                if (this.activeTocItem !== tocItem) {
                    var portion = (_b = this.originalDocument.querySelector("[id=\"".concat(tocItem.id, "\"]"))) === null || _b === void 0 ? void 0 : _b.cloneNode(true);
                    this.documentElement.replaceChildren(portion);
                    this.activeTocItem = tocItem;
                }
            }
        }
    };
    /**
     * When the user is focused only on a certain portion of the document, update the social sharing mechanisms
     * to reflect that.
     * @param portion a description of the portion being shared
     */
    DocumentContent.prototype.setSharedPortion = function (portion) {
        if (portion === void 0) { portion = ''; }
        var parts = [this.root.dataset.title];
        if (portion)
            parts.push(portion);
        parts.push(window.location.toString());
        var text = parts.join(' - ');
        for (var _i = 0, _a = Array.from(document.querySelectorAll('.share-link')); _i < _a.length; _i++) {
            var el = _a[_i];
            var a = el;
            var url = new URL(a.href);
            for (var _b = 0, _c = Array.from(url.searchParams.keys()); _b < _c.length; _b++) {
                var key = _c[_b];
                if (key === 'text') {
                    url.searchParams.set(key, text);
                }
            }
            a.href = url.toString();
        }
    };
    return DocumentContent;
}());
export default DocumentContent;
