import { createTocController } from '../utils/function';
import i18next from 'i18next';
var TaxonomyTree = /** @class */ (function () {
    function TaxonomyTree(root) {
        var tocController = createTocController();
        var jsonElement = document.getElementById('taxonomy_tree');
        var data = jsonElement && jsonElement.textContent ? JSON.parse(jsonElement.textContent) : [];
        var urlParts = window.location.pathname.split('/');
        var currentItem = urlParts[urlParts.length - 1];
        // collapsed by default
        var expanded = false;
        // data-slug-root should always have a value. Contractual dom agreement.
        var slugRoot = root.dataset.rootSlug || '';
        var prefix = root.dataset.prefix || 'taxonomy';
        function formatItem(x) {
            var children = (x.children || []).map(function (y) { return formatItem(y); });
            return {
                title: x.data.name,
                slug: x.data.slug,
                href: "/".concat(prefix, "/").concat(slugRoot, "/").concat(x.data.slug),
                expanded: expanded || x.data.slug === currentItem || children.some(function (y) { return y.expanded; }),
                children: children
            };
        }
        tocController.expanded = expanded;
        tocController.items = data[0].children.map(function (item) { return formatItem(item); });
        tocController.expandAllBtnText = i18next.t('Expand all');
        tocController.collapseAllBtnText = i18next.t('Collapse all');
        tocController.titleFilterPlaceholder = i18next.t('Search');
        // If the currentItem is the same as the la-toc-item's anchor element taxonomy, then apply an active css class
        tocController.addEventListener('itemRendered', function (e) {
            var tocItem = e.target;
            if (!tocItem)
                return;
            var hrefElement = tocItem.querySelector('.content__action__title');
            if (!hrefElement)
                return;
            // @ts-ignore
            if (tocItem.item && tocItem.item.slug === currentItem) {
                hrefElement.classList.add('active');
            }
        });
        root.appendChild(tocController);
    }
    return TaxonomyTree;
}());
export default TaxonomyTree;
