var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import components from './components';
// @ts-ignore
import { vueI18n } from './i18n';
import { createAndMountApp } from './utils/vue-utils';
import '@lawsafrica/law-widgets/dist/components/la-akoma-ntoso';
import '@lawsafrica/law-widgets/dist/components/la-gutter';
import '@lawsafrica/law-widgets/dist/components/la-gutter-item';
import '@lawsafrica/law-widgets/dist/components/la-table-of-contents-controller';
import '@lawsafrica/law-widgets/dist/components/la-decorate-external-refs';
import '@lawsafrica/law-widgets/dist/components/la-decorate-internal-refs';
import '@lawsafrica/law-widgets/dist/components/la-decorate-terms';
// @ts-ignore
import htmx from 'htmx.org';
var PeachJam = /** @class */ (function () {
    function PeachJam() {
        this.config = {
            appName: 'Peach Jam',
            pdfWorker: '/static/js/pdf.worker-prod.js',
            userHelpLink: '',
            sentry: {
                dsn: null,
                environment: null
            }
        };
        this.components = [];
    }
    PeachJam.prototype.setup = function () {
        this.setupConfig();
        // add the current user agent to the root HTML element for use with pocketlaw
        document.documentElement.setAttribute('data-user-agent', navigator.userAgent.toLowerCase());
        this.setupHtmx();
        this.setupSentry();
        this.createComponents(document.body);
        this.createVueComponents(document.body);
        this.setupTooltips();
        this.setupPopovers();
        this.scrollNavTabs();
        this.clearGACookies();
    };
    PeachJam.prototype.setupConfig = function () {
        var _a;
        var data = (_a = document.getElementById('peachjam-config')) === null || _a === void 0 ? void 0 : _a.innerText;
        if (data) {
            this.config = JSON.parse(data);
        }
    };
    PeachJam.prototype.setupHtmx = function () {
        var _this = this;
        window.htmx = htmx;
        // htmx:load is fired both when the page loads (weird) and when new content is loaded. We only care about the latter
        // case. See https://github.com/bigskysoftware/htmx/issues/1500
        var htmxHelper = { firstLoad: true };
        document.body.addEventListener('htmx:load', function (e) {
            if (htmxHelper.firstLoad) {
                htmxHelper.firstLoad = false;
                return;
            }
            // mount components on new elements
            _this.createComponents(e.target);
            _this.createVueComponents(e.target);
        });
    };
    PeachJam.prototype.createComponents = function (root) {
        if (root.getAttribute('data-component')) {
            this.createComponent(root);
        }
        // @ts-ignore
        for (var _i = 0, _a = root.querySelectorAll('[data-component]'); _i < _a.length; _i++) {
            var element = _a[_i];
            this.createComponent(element);
        }
    };
    PeachJam.prototype.createVueComponents = function (root) {
        // create vue-based components
        // @ts-ignore
        for (var _i = 0, _a = root.querySelectorAll('[data-vue-component]'); _i < _a.length; _i++) {
            var element = _a[_i];
            this.createVueComponent(element);
        }
    };
    PeachJam.prototype.createComponent = function (el) {
        var name = el.getAttribute('data-component');
        if (name && components[name]) {
            // create the component and attached it to the HTML element
            el.component = new components[name](el);
            this.components.push(el.component);
        }
    };
    PeachJam.prototype.createVueComponent = function (el) {
        // create vue-based components
        var name = el.getAttribute('data-vue-component');
        if (name && components[name]) {
            var vueComp = components[name];
            createAndMountApp({
                component: vueComp,
                // pass in the element's data attributes as props
                props: __assign({}, el.dataset),
                use: [vueI18n],
                mountTarget: el
            });
            el.component = vueComp;
            this.components.push(vueComp);
        }
    };
    PeachJam.prototype.setupSentry = function () {
        // @ts-ignore
        if (this.config.sentry && window.Sentry) {
            // @ts-ignore
            window.Sentry.init({
                dsn: this.config.sentry.dsn,
                environment: this.config.sentry.environment,
                allowUrls: [
                    new RegExp(window.location.host.replace('.', '\\.') + '/static/')
                ],
                denyUrls: [
                    new RegExp(window.location.host.replace('.', '\\.') + '/static/lib/pdfjs/')
                ],
                beforeSend: function (event) {
                    try {
                        // if there is no stacktrace, ignore it
                        if (!event.exception || !event.exception.values || !event.exception.values[0] || !event.exception.values[0].stacktrace) {
                            return null;
                        }
                        var frames_1 = event.exception.values[0].stacktrace.frames;
                        // if all frames are anonymous, don't send this event
                        // see https://github.com/getsentry/sentry-javascript/issues/3147
                        if (frames_1 && frames_1.length > 0 && frames_1.every(function (f) { return f.filename === '<anonymous>'; })) {
                            return null;
                        }
                    }
                    catch (e) {
                        // ignore error, send event
                    }
                    return event;
                }
            });
        }
    };
    PeachJam.prototype.setupTooltips = function () {
        // setup bootstrap tooltips
        document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(function (el) {
            // @ts-ignore
            new window.bootstrap.Tooltip(el);
        });
    };
    PeachJam.prototype.setupPopovers = function () {
        document.querySelectorAll('[data-bs-toggle="help-popover"]').forEach(function (el) {
            var _a;
            // @ts-ignore
            var helpPopover = new window.bootstrap.Popover(el, {
                html: true,
                content: "\n        ".concat(el.getAttribute('data-bs-content'), "\n         <div><a href=\"").concat(((_a = el.getAttribute('href')) === null || _a === void 0 ? void 0 : _a.split('#')[1]) || '#', "\" target='_blank' rel='noopener noreferrer'>Learn more</a></div>\n        "),
                container: 'body'
            });
            // keep popover open when clicking inside it but close when clicking outside it
            el.addEventListener('inserted.bs.popover', function (e) {
                var popoverBody = document.querySelector('.popover-body');
                var clickListener = function (e) {
                    // Close the popover if the click is outside the popover
                    if (!(popoverBody === null || popoverBody === void 0 ? void 0 : popoverBody.contains(e.target))) {
                        helpPopover.hide();
                        document.removeEventListener('click', clickListener);
                    }
                };
                document.addEventListener('click', clickListener);
            });
        });
    };
    PeachJam.prototype.scrollNavTabs = function () {
        // for scrollable tabbed navs, the active element may be out of view on narrow devices; scroll it into view
        document.querySelectorAll('.nav.nav-tabs.scroll-xs > .nav-item > .nav-link.active').forEach(function (link) {
            if (link.parentElement && link.parentElement.parentElement) {
                link.parentElement.parentElement.scrollLeft = link.offsetLeft;
            }
        });
    };
    PeachJam.prototype.clearGACookies = function () {
        // if window.dataLayer is not set, then Google Analytics is not enabled, but there may be cookies still set; clear them
        // @ts-ignore
        if (!window.dataLayer) {
            var cookies = [];
            try {
                cookies = document.cookie.split(';');
            }
            catch (_a) {
                // ignore security errors when reading cookies
                return;
            }
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i];
                var eqPos = cookie.indexOf('=');
                var name_1 = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
                if (name_1.trim().startsWith('_ga')) {
                    document.cookie = "".concat(name_1, "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.").concat(window.location.hostname);
                }
            }
        }
    };
    return PeachJam;
}());
var peachJam = new PeachJam();
export default peachJam;
