import { CopyToClipboard } from './clipboard';
import { RelationshipEnrichments } from './RelationshipEnrichment';
import DocumentFilterForm from './document-filter-form';
import DocumentTable from './document-table';
import DocumentContent from './DocumentContent/index';
import NavigationSelect from './navigation-select';
import { ToggleTab } from './tabs';
import TaxonomyTree from './taxonomy-tree';
import TermsOfUse from './terms-of-use';
import DocumentProblemModal from './DocumentProblemModal.vue';
import FindDocuments from './FindDocuments/index.vue';
import PocketLawDownload from './PocketLawDownload.vue';
var components = {
    // Data components
    CopyToClipboard: CopyToClipboard,
    DocumentContent: DocumentContent,
    DocumentFilterForm: DocumentFilterForm,
    DocumentTable: DocumentTable,
    NavigationSelect: NavigationSelect,
    RelationshipEnrichments: RelationshipEnrichments,
    ToggleTab: ToggleTab,
    TaxonomyTree: TaxonomyTree,
    TermsOfUse: TermsOfUse,
    // Vue components
    DocumentProblemModal: DocumentProblemModal,
    FindDocuments: FindDocuments,
    PocketLawDownload: PocketLawDownload
};
export default components;
